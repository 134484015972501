



























import { DateTime } from 'luxon';
import { namespace } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DealTrackingDataStoreActions, DealTrackingDataStoreGetters } from '@/store/dealTrackingData.store';
import DealTrackingData from '@/models/DealTrackingData.model';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';
import AxiosErrorHandlerMixin from '@/misc/AxiosErrorHandler.mixin';

const TrackingStore = namespace('trackingData');

@Component({
  components: {
    DateRangeSliderComponent: () => import(
      /* webpackChunkName: "DateRangeSliderComponent" */
      '@/components/DateRangeSlider.component.vue'
    ),
    TrackingDataListComponent: () => import(
      /* webpackChunkName: "TrackingDataListComponent" */
      '@/components/TrackingDataList.component.vue'
    ),
    KpiSummaryComponent: () => import(
      /* webpackChunkName: "KpiSummaryComponent" */
      '@/components/KpiSummary.component.vue'
    )
  }
})
export default class TrackingDataView extends mixins(BaseMixin, AxiosErrorHandlerMixin) {
  @Prop({ required: true })
  public storeId!: string;

  @TrackingStore.Action(DealTrackingDataStoreActions.GET_BY_STORE)
  private getAllInRangeAction!: (payload: { storeId: string, fromDate: DateTime, toDate: DateTime }) => Promise<DealTrackingData[]>;

  @TrackingStore.Getter(DealTrackingDataStoreGetters.ITEMS_BY_STORE)
  private trackingData!: DealTrackingData[];

  @TrackingStore.Getter(DealTrackingDataStoreGetters.TOTAL_NUMBER_OF_CLICKS)
  private totalNumberOfClicks!: number;
  @TrackingStore.Getter(DealTrackingDataStoreGetters.TOTAL_NUMBER_OF_USERS)
  private totalNumberOfUsers!: number;
  @TrackingStore.Getter(DealTrackingDataStoreGetters.TOTAL_BUDGET)
  private totalBudget!: number;
  @TrackingStore.Getter(DealTrackingDataStoreGetters.TOTAL_USED_BUDGET)
  private totalUsedBudget!: number;
  @TrackingStore.Getter(DealTrackingDataStoreGetters.TOTAL_REMAINING_BUDGET)
  private totalRemainingBudget!: number;

  private minDate: DateTime = DateTime.now().minus({ years: 1 });
  private maxDate: DateTime = DateTime.now();

  private isLoading: boolean = false;

  async created() {
    try {
      this.isLoading = true;
      await this.getAllInRangeAction({ storeId: this.storeId, fromDate: this.minDate, toDate: this.maxDate });
    } catch (e) {
      this.handleAxiosError(e);
    } finally {
      this.isLoading = false;
    }
  }

  private async onDateRangeChanged(fromDate: DateTime, toDate: DateTime) {
    try {
      this.isLoading = true;
      await this.getAllInRangeAction({ storeId: this.storeId, fromDate: fromDate, toDate: toDate });
    } catch (e) {
      this.handleAxiosError(e);
    } finally {
      this.isLoading = false;
    }
  }

  private getProgressBarColor(value: number): string {
    if (value <= BaseMixin.THRESHOLD_RED) {
      return 'error';
    } else if (value >= BaseMixin.THRESHOLD_GREEN) {
      return 'success';
    } else {
      return 'warning';
    }
  }
}
